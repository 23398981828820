<template>
  <VueDeckgl
    :width="`100%`"
    :height="'100%'"
    :layers="layers"
    :viewState="viewState"
    @click="handleClick"
    @view-state-change="updateViewState"
  >
    <div id="map" ref="map"></div>
    <div class="layer-controls">
      <div class="flex justify-end">
        <i
          v-if="!toggleBarForControl"
          class="fas fa-sliders cursor-pointer"
          @click="toggleBarForControl = !toggleBarForControl"
        ></i>

        <i
          v-if="toggleBarForControl"
          class="fas fa-minus cursor-pointer"
          @click="toggleBarForControl = !toggleBarForControl"
        ></i>
      </div>

      <!-- Map Style Controls -->
      <div v-if="toggleBarForControl" class="grid grid-cols-2 text-sm">
        <div class="map-style-controls">
          <p class="font-bold mb-1">Style</p>
          <label>
            <input
              type="radio"
              value="light-v11"
              name="mapstyle"
              v-model="selectedMapStyle"
            />
            Light
          </label>
          <label>
            <input
              type="radio"
              value="dark-v11"
              name="mapstyle"
              v-model="selectedMapStyle"
            />
            Dark
          </label>
          <label>
            <input
              type="radio"
              value="satellite-v9"
              name="mapstyle"
              v-model="selectedMapStyle"
            />
            Satellite
          </label>
        </div>
        <div class="map-style-controls">
          <p class="font-bold mb-1">Layer</p>
          <label>
            <input type="checkbox" v-model="showHexagonLayer" /> Hexagon
          </label>
          <label>
            <input type="checkbox" v-model="showHeatmapLayer" /> Heatmap
          </label>
        </div>
        <div class="grid col-span-12">
          <p class="font-bold mb-1">Intensity</p>
          <ColorIntensityBox />
        </div>
      </div>
    </div>
    <div class="info mt-1">
      <div class="flex justify-end">
        <i
          v-if="!toggleBarForInfo"
          class="fas fa-circle-info cursor-pointer"
          @click="toggleBarForInfo = !toggleBarForInfo"
        ></i>

        <i
          v-if="toggleBarForInfo"
          class="fas fa-minus cursor-pointer"
          @click="toggleBarForInfo = !toggleBarForInfo"
        ></i>
      </div>
      <div v-if="toggleBarForInfo" class="text-sm">
        <p class="font-bold mb-1">Guideline</p>
        <GuidelineBox />
      </div>
    </div>
  </VueDeckgl>
</template>

<script>
import { HexagonLayer, HeatmapLayer } from '@deck.gl/aggregation-layers'
import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import VueDeckgl from '@/views/dashboard/DemandHeatmapV2/VueDeckgl.vue'
import ColorIntensityBox from '@/views/dashboard/DemandHeatmapV2/ColorIntensityBox.vue'
import GuidelineBox from '@/views/dashboard/DemandHeatmapV2/GuidelineBox.vue'

export default {
  components: {
    VueDeckgl,
    ColorIntensityBox,
    GuidelineBox,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    isCurrentView: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      toggleBarForControl: false,
      toggleBarForInfo: false,
      accessToken:
        'pk.eyJ1IjoidmhvaW5haSIsImEiOiJjbTBvMmgxbHcwNDBhMmxvaGxvajM5eDY3In0.B1h5M6Gt2bXLU89w_FfN8A',
      mapStyle: 'mapbox://styles/mapbox/light-v11',
      viewState: {
        latitude: 23.810331,
        longitude: 90.412521,
        zoom: 4,
        bearing: 0,
        pitch: 45,
      },
      COLOR_RANGE: [
        //green
        [163, 230, 53],
        [132, 204, 22],
        [1, 163, 13],
        //yellow
        [250, 204, 21],
        [234, 179, 8],
        [202, 138, 4],
        //red
        [248, 113, 113],
        [220, 38, 38],
        [185, 28, 28],
      ],
      map: null,
      showHexagonLayer: true, // Control visibility of Hexagon Layer
      showHeatmapLayer: true, // Control visibility of Heatmap Layer
      selectedMapStyle: 'dark-v11',
    }
  },
  computed: {
    layers() {
      const layers = []

      if (this.showHexagonLayer) {
        const hexagonLayer = new HexagonLayer({
          id: 'HexagonLayer',
          data: this.data,
          extruded: true,
          getPosition: (d) => d.COORDINATES,
          getColorWeight: (d) => d.SPACES,
          getElevationWeight: (d) => d.SPACES,
          elevationScale: 1,
          radius: 5,
          pickable: true,
          colorRange: this.COLOR_RANGE,
        })
        layers.push(hexagonLayer)
      }

      if (this.showHeatmapLayer) {
        // Proper instantiation with 'new'
        const heatmapLayer = new HeatmapLayer({
          id: 'HeatmapLayer',
          data: this.data,
          aggregation: 'SUM',
          getPosition: (d) => d.COORDINATES,
          getWeight: (d) => d.SPACES,
          colorRange: this.COLOR_RANGE,
          radiusPixels: 25,
        })
        layers.push(heatmapLayer)
      }

      return layers
    },
  },

  watch: {
    isCurrentView: {
      deep: true,
      immediate: true,
      handler(status) {
        if (status) {
          this.mapRefresh()
        }
      },
    },
    selectedMapStyle: {
      deep: true,
      immediate: true,
      handler(newStyle) {
        this.mapStyle = `mapbox://styles/mapbox/${newStyle}`
        if (this.map) {
          this.map.setStyle(this.mapStyle) // Dynamically change the map style
        }
      },
    },
    data: {
      deep: true,
      immediate: true,
      handler() {
        this.mapRefresh()
        this.updateCenterView()
      },
    },
    toggleBarForControl: {
      deep: true,
      immediate: false,
      handler(value) {
        if (value) {
          this.toggleBarForInfo = false
        }
      },
    },
    toggleBarForInfo: {
      deep: true,
      immediate: false,
      handler(value) {
        if (value) {
          this.toggleBarForControl = false
        }
      },
    },
  },
  methods: {
    mapRefresh() {
      this.map.resize()
      this.viewState.zoom = 7
    },
    // Function to calculate bounds
    calculateBounds() {
      const coordinates = this.data.map((d) => d.COORDINATES)
      const bounds = new mapboxgl.LngLatBounds(coordinates[0], coordinates[0])

      // Expand bounds to include all coordinates
      coordinates.forEach((coord) => bounds.extend(coord))

      return bounds
    },

    updateViewState(viewState) {
      this.viewState = { ...viewState }

      this.map.jumpTo({
        center: [viewState.longitude, viewState.latitude],
        zoom: viewState.zoom,
        bearing: viewState.bearing,
        pitch: viewState.pitch,
      })
    },
    handleClick({ event, info }) {
      console.log('handleClick', event, info)
    },
    updateCenterView() {
      const bounds = this.calculateBounds()
      this.map.fitBounds(bounds, { padding: 20 })
      const center = bounds.getCenter()

      this.viewState = {
        ...this.viewState,
        longitude: center.lng,
        latitude: center.lat,
      }
    },
  },
  mounted() {
    // Creating the map
    this.map = new mapboxgl.Map({
      accessToken: this.accessToken,
      container: this.$refs.map,
      interactive: true,
      style: this.mapStyle,
      center: [this.viewState.longitude, this.viewState.latitude],
      zoom: this.viewState.zoom,
      pitch: this.viewState.pitch,
      bearing: this.viewState.bearing,
    })
    // Fit the map to the data bounds

    this.map.on('load', () => {
      this.updateCenterView()
    })

    setTimeout(() => {
      const { latitude, longitude, pitch, bearing, zoom } = this.viewState
      console.log('mounted', zoom)
      this.viewState = {
        latitude,
        longitude,
        pitch,
        bearing,
        zoom,
        transitionDuration: 3000,
      }
    }, 5000)
  },
}
</script>

<style>
#map {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #e5e9ec;
  overflow: hidden;
}
.info {
  position: absolute;
  top: 40px;
  left: 10px;
  background: rgba(255, 255, 255, 1);
  padding: 6px 10px;
  border-radius: 6px;
  z-index: 99;
}
.layer-controls {
  position: absolute;
  top: 10px;
  left: 10px;
  background: rgba(255, 255, 255, 1);
  padding: 6px 10px;
  border-radius: 6px;
  z-index: 100;
}

.layer-controls label {
  display: block;
  margin-bottom: 5px;
}

.map-style-controls {
  margin-top: 2px;
}

.map-style-controls label {
  display: block;
  margin-bottom: 5px;
}
</style>
